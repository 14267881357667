import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import CTA from "../components/CTA"
import SEO from "../components/SEO"
import WipeScreen from "../components/WipeScreen"

import { Container, Row, Col } from "reactstrap"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string
    }
  }
}

const PrivacyPolicy: React.FC<PageProps<DataProps>> = () => {
  return (
    <>
      <SEO title={"Privacy Policy"} slug={"privacy-policy"} />
      <Row className="white-container case-study">
        <Container className="mt-5 pt-5">
          <Row className="mt-5 pt-5 no-gutters">
            <Col sm="6" className="super-margin">
              <h1>We are serious about protecting your data.</h1>
              <br />
              <p>Privacy Policy</p>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="yellow-container">
        <Container>
          <Row className="mt-5 no-gutters">
            <Col sm="6" className="super-margin">
              <h4>
                This privacy policy sets out how we collect, use, process and
                protect any information that you provide when you use our
                website or services.
              </h4>
            </Col>
          </Row>
          <Row className="no-gutters mt-5 mb-5">
            <Col md="4"></Col>
            <Col md="7" className="long">
              <div className="article">
                <h4># 1: Who is the administrator of your personal data?</h4>
                <p>
                  The administrator of your personal data is Pawel Malenczak,
                  conducting business activity under the company name "PROJECT
                  SCOPE Pawel Malenczak," ul. Bonifraterska 17, 00-203 Warsaw,
                  Poland, NIP/VAT-EU: 7481543521.
                </p>
                <h4>
                  # 2: Who can you contact regarding the processing of your
                  personal data?
                </h4>
                <p>
                  As part of implementing personal data protection in our
                  company, we have decided not to appoint a Data Protection
                  Officer since it is not mandatory in our case. For matters
                  related to personal data protection and privacy, you can
                  contact us via email at hello@projectscope.eu.
                </p>
                <h4># 3: What information do we have about you?</h4>
                <p>
                  Depending on the purpose, we may process the following
                  information about you:
                </p>
                <p>
                  <ul>
                    <li>First and last name</li>
                    <li>Residential address</li>
                    <li>Business address</li>
                    <li>Tax identification number/VAT-UE</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Data included in email correspondence</li>
                    <li>Bank account number</li>
                    <li>IP address</li>
                    <li>Approximate location</li>
                  </ul>
                </p>
                <p>
                  The scope of processed data has been described precisely in
                  relation to each processing purpose. More information on this
                  is provided later in this privacy policy. Furthermore, we use
                  tools that collect various information about you related to
                  your use of our website. This includes:
                </p>
                <p>
                  <ul>
                    <li>
                      Information about the operating system and internet
                      browser
                    </li>
                    <li>Visited subpages</li>
                    <li>Time spent on the website</li>
                    <li>Transitions between individual subpages</li>
                    <li>Clicks on specific links</li>
                    <li>The source from which you access the website</li>
                    <li>Age range</li>
                    <li>Gender</li>
                    <li>Approximate location limited to the locality</li>
                    <li>Your interests determined based on online activity</li>
                  </ul>
                </p>
                <p>
                  These pieces of information are referred to as "Anonymous
                  Information" in this privacy policy. Anonymous Information
                  does not, by itself, constitute personal data because it does
                  not allow us to identify you, and we do not link it to typical
                  personal data that we collect about you. Nevertheless,
                  considering the strict case law of the Court of Justice of the
                  European Union, we have included detailed explanations
                  regarding the processing of this information in this privacy
                  policy.
                </p>
                <p>
                  Since Anonymous Information is collected by external tools we
                  use (Google Analytics), it is also processed by the providers
                  of these tools according to their terms and privacy policies.
                </p>
                <p>
                  Anonymous Information is used for analytical and statistical
                  purposes. It is also used by providers of specific tools to
                  provide and improve services, manage them, develop new
                  services, measure the effectiveness of advertisements, protect
                  against fraud and abuse, and personalize content and ads
                  displayed on various services, websites, and applications.
                </p>
                <h4># 4: Where do we get your personal data from?</h4>
                <p>
                  In most cases, you provide your data to us yourself. This
                  happens when you:
                </p>
                <p>
                  <ul>
                    <li>Browse our website</li>
                    <li>Contact us via email</li>
                    <li>Follow our social media profiles</li>
                    <li>Interact with content we publish on social media</li>
                  </ul>
                </p>
                <p>
                  Additionally, some information about you may be automatically
                  collected by the tools we use:
                </p>
                <p>
                  <ul>
                    <li>The website's mechanism collects your IP address</li>
                    <li>
                      Google Analytics collects Anonymous Information related to
                      your actions on the website.
                    </li>
                  </ul>{" "}
                </p>
                <h4># 5: Are your data safe?</h4>
                <p>
                  We take the security of your personal data seriously. We have
                  analyzed the risks associated with various data processing
                  processes and implemented appropriate security measures and
                  data protection. We continually monitor the state of the
                  technical infrastructure, train the staff, review relevant
                  procedures, and introduce necessary improvements. If you have
                  any questions about your personal data, we am available at{" "}
                  <a href="mailto:hello@projectscope.eu">
                    hello@projectscope.eu
                  </a>
                  .
                </p>
                <h4>
                  # 6: For what purposes do we process your personal data?
                </h4>
                <p>
                  There are several purposes for processing your data. Here is a
                  list of them, followed by more detailed explanations. Each
                  processing purpose is associated with specific legal grounds
                  for processing:
                </p>
                <p>
                  <ul>
                    <li>
                      Handling communication (emails, communication tools /
                      apps) – Article 6(1)(f) of the GDPR
                    </li>
                    <li>
                      Fulfillment of tax and accounting obligations – Article
                      6(1)(f) of the GDPR
                    </li>
                    <li>
                      Social media management – Article 6(1)(f) of the GDPR
                    </li>
                    <li>
                      Analysis and statistics (only Anonymous Information) –
                      Article 6(1)(f) of the GDPR
                    </li>
                  </ul>
                </p>
                <h5>
                  Handling communication (emails, communication tools / apps) –
                  Details:
                </h5>
                <p>
                  When you contact us via email or online communication
                  tools/apps, you naturally provide your personal data included
                  in the content, including but not limited to your email
                  address, handle and/or first and last name. Providing this
                  data is voluntary but necessary to establish contact. Your
                  data in this case is processed for the purpose of
                  communication with you, and the legal basis for processing is
                  Article 6(1)(f) of the GDPR.
                </p>
                <p>
                  The legal basis for processing after the contact has ended is
                  also our legitimate interest in the form of archiving
                  correspondence for the purpose of being able to demonstrate
                  specific facts in the future (Article 6(1)(f) of the GDPR).
                  The content of the correspondence may be subject to archiving,
                  and we cannot definitively specify when it will be deleted.
                </p>
                <p>
                  You have the right to request the history of correspondence
                  with us (if it has been archived), which may contain your
                  personal data. This will also allow you to receive a copy of
                  your data from me.
                </p>
                <h5>
                  Fulfillment of tax and accounting obligations – Details:
                </h5>
                <p>
                  We process your personal data to the extent and for the
                  purpose necessary to fulfill the tax and accounting
                  obligations imposed on us by law. In this case, the legal
                  basis for processing is Article 6(1)(c) of the GDPR in
                  connection with tax and accounting regulations.
                </p>
                <p>
                  In addition, the data is also processed for our legitimate
                  interest in the form of archiving documents for the purpose of
                  being able to demonstrate specific facts in the future
                  (Article 6(1)(f) of the GDPR). The content of the documents
                  may be subject to archiving, and we cannot definitively
                  specify when it will be deleted.
                </p>
                <h5>Social media management – Details:</h5>
                <p>
                  We process your personal data to the extent and for the
                  purpose necessary to manage our social media profiles. This
                  includes data provided voluntarily, e.g., in comments and in
                  your profile. Providing this data is voluntary but necessary
                  to participate in social media activities, e.g., commenting on
                  entries or sending messages. The legal basis for processing is
                  Article 6(1)(f) of the GDPR.
                </p>
                <h5>
                  Analysis and statistics (only Anonymous Information) –
                  Details:
                </h5>
                <p>
                  We process Anonymous Information for analytical and
                  statistical purposes. Anonymous Information is not personal
                  data. The legal basis for processing is Article 6(1)(f) of the
                  GDPR.
                </p>
                <h4># 7: Who can we pass your data to?</h4>
                <p>
                  In certain situations, we may transfer your data to third
                  parties, but only to the extent and for the purpose necessary
                  for the proper execution of the service. we may share your
                  personal data with the following categories of recipients:
                </p>
                <p>
                  <ul>
                    <li>Entities providing IT and hosting services</li>
                    <li>Entities providing online communication services</li>
                    <li>Entities providing accounting and legal services</li>
                    <li>
                      Entities providing analytical and statistical services
                    </li>
                    <li>Entities providing payment services</li>
                    <li>Entities providing debt collection services</li>
                  </ul>
                </p>
                <p>
                  If we are legally obliged to do so, we may also disclose your
                  data to authorized state authorities. we will also disclose
                  your data to authorized state authorities if this is necessary
                  for the establishment, exercise, or defense of legal claims.
                </p>
                <p>
                  Furthermore, when it comes to Anonymous Information, access to
                  them is available to providers of tools that collect Anonymous
                  Information. These tool providers are independent data
                  controllers for the information they gather and may share this
                  data according to their own terms and privacy policies, over
                  which we have no control.
                </p>
                <h4># 8: How long will we process your data?</h4>
                <p>
                  Your personal data will be processed for the period necessary
                  to achieve the purposes for which they were collected. After
                  this period, your data will be deleted. The exact retention
                  period may vary depending on the purpose of processing and the
                  nature of the data.
                </p>
                <h5>Handling communication:</h5>
                <p>
                  We will process your data until the end of communication with
                  you, and in the case of correspondence archiving for the
                  purposes of potential defense, establishment, or pursuit of
                  claims, the data will be processed indefinitely for our
                  legitimate interest.
                </p>
                <h5>Fulfillment of tax and accounting obligations:</h5>
                <p>
                  We will process your data for the period necessary to fulfill
                  the tax and accounting obligations imposed on us by law, and
                  in the case of archiving documents for the purposes of
                  potential defense, establishment, or pursuit of claims, the
                  data will be processed for the time necessary to fulfill the
                  obligation imposed on us by law.
                </p>
                <h5>Social media management:</h5>
                <p>
                  We will process your data for the duration of our activity on
                  social media. If your data is processed by the administrators
                  of social media websites, the retention period may vary and
                  depend on their rules.
                </p>
                <h5>Analysis and statistics (only Anonymous Information):</h5>
                <p>
                  Anonymous Information will be processed for analytical and
                  statistical purposes for the time necessary to achieve these
                  purposes.
                </p>
                <h4># 9: What are your rights?</h4>
                <p>
                  You have several rights related to the processing of your
                  personal data. These rights include:
                </p>
                <p>
                  <ul>
                    <li>The right to access your personal data</li>
                    <li>The right to rectify your personal data</li>
                    <li>The right to erase your personal data</li>
                    <li>
                      The right to restrict the processing of your personal data
                    </li>
                    <li>The right to data portability</li>
                    <li>
                      The right to object to the processing of your personal
                      data
                    </li>
                    <li>
                      The right to withdraw your consent to the processing of
                      personal data, if applicable
                    </li>
                  </ul>
                </p>
                <p>
                  You can exercise your rights by sending a relevant request to{" "}
                  <a href="mailto:hello@projectscope.eu">
                    hello@projectscope.eu
                  </a>
                  . We will respond to your request as soon as possible, but no
                  later than within one month of receiving it. If necessary, due
                  to the complexity of the request or the number of requests, we
                  may extend this period by two months, about which we will
                  inform you.
                </p>
                <p>
                  If you believe that your personal data is being processed
                  unlawfully, you have the right to lodge a complaint with the
                  President of the Personal Data Protection Office in Poland{" "}
                  <a href="https://uodo.gov.pl/en">https://uodo.gov.pl/en</a>.
                </p>
                <h4>
                  # 10: Do we process your data automatically for purposes of
                  profiling?
                </h4>
                <p>No.</p>
                <h4>
                  # 11: Do we transfer your data to third countries or
                  international organizations?
                </h4>
                <p>
                  We do not transfer your personal data to countries outside the
                  European Union (so-called third countries) or international
                  organizations.
                </p>
                <h4># 12: Do we use cookies, and what are they exactly?</h4>
                <p>
                  Cookies are small text information stored on your end device
                  (e.g., computer, tablet, smartphone) that can be read by our
                  teleinformatics system (first-party cookies) or third-party
                  teleinformatics systems (third-party cookies). Specific
                  information may be saved and stored in cookies, which
                  teleinformatics systems can later access for specific
                  purposes. Some of the cookies we use are deleted after you
                  close your web browser session (session cookies). Others are
                  stored on your end device and allow your browser to be
                  recognized when you revisit the site (persistent cookies).
                </p>
                <h4># 13: On what basis do we use cookies?</h4>
                <p>
                  We use cookies based on your consent, except in cases where
                  cookies are necessary for the proper provision of an
                  electronic service to you. Cookies that are not essential for
                  providing an electronic service remain blocked until you give
                  your consent to use them. During your first visit to our
                  website, we display a message asking for your consent and
                  providing you with options to manage cookies, i.e., decide
                  which cookies you accept and which you want to block. Please
                  note that disabling or limiting cookie support may prevent you
                  from using some of the features available on our website, as
                  well as other websites that use cookies.
                </p>
                <h4># 14: Can you disable cookies?</h4>
                <p>
                  Yes, you can manage cookie settings within your web browser.
                  You can block all or select cookies, as well as block cookies
                  from specific websites. You can also delete previously stored
                  cookies and other website data at any time. Web browsers also
                  offer incognito mode, which you can use if you do not want
                  information about visited websites and downloaded files to be
                  saved in your browsing and download history. Cookies created
                  in incognito mode are deleted when you close all windows of
                  that mode.
                </p>
                <p>
                  Browser extensions are also available that allow you to
                  control cookies, such as Ghostery, and additional software,
                  especially antivirus packages, may offer cookie control
                  options. Additionally, there are online tools available that
                  enable you to control certain types of cookies, particularly
                  for managing behavioral advertising settings.
                </p>
                <p>
                  We also provide you with the ability to control cookies
                  directly from our website. We have implemented a special
                  cookie management mechanism that allows you to block cookies.
                </p>{" "}
                <h4># 15: What are the purposes of our own cookies?</h4>
                <p>
                  Our own cookies are used to ensure the proper functioning of
                  various website mechanisms, such as ensuring the proper
                  transmission of forms visible on the website. Our own cookies
                  also store information about the cookie settings you have
                  defined from the cookie management mechanism.
                </p>
                <h4># 16: What third-party cookies are used?</h4>
                <p>
                  The following third-party cookies are used on our website:
                </p>
                <p>
                  <ul>
                    <li>Google Analytics</li>
                  </ul>
                </p>
                <p>
                  Details about individual third-party cookies are described
                  below.
                </p>
                <h5>Google Analytics – Details</h5>
                <p>
                  We use the Google Analytics tool provided by Google LLC, 1600
                  Amphitheatre Parkway, Mountain View, CA 94043, USA. We
                  implement these actions based on our legitimate interest,
                  which involves creating statistics and analyzing them to
                  optimize the website. To use Google Analytics, we have
                  implemented special tracking code in the website's code.
                </p>
                <p>
                  The tracking code uses cookies from Google LLC related to the
                  Google Analytics service. You can block Google Analytics
                  tracking code at any time by installing a browser add-on
                  provided by Google. Google Analytics automatically collects
                  information about your use of the website. The information
                  collected is often sent to Google servers, which may be
                  located worldwide, and stored there.
                </p>
                <p>
                  Due to the anonymization of the IP address activated by me,
                  your IP address is shortened before further transmission. In
                  exceptional cases, the full IP address is sent to Google
                  servers and then shortened. The anonymized IP address
                  transmitted by your browser as part of Google Analytics is
                  generally not linked to other Google data. I want to emphasize
                  that We do not collect any data through Google Analytics that
                  would allow for your identification. Therefore, the data
                  collected through Google Analytics is not considered personal
                  data.
                </p>
                <p>
                  The information we have access to through Google Analytics
                  includes, in particular: information about the operating
                  system and web browser, the pages you visit, time spent on the
                  website and its individual pages, transitions between
                  individual pages, the source from which you came to our
                  website, and your approximate location limited to the city.
                </p>
                <p>
                  Google Analytics and Google Analytics 360 services have
                  obtained the ISO 27001 independent security standard
                  certificate. ISO 27001 is one of the most widely recognized
                  standards in the world and certifies compliance with the
                  relevant requirements for systems that support Google
                  Analytics and Google Analytics 360. If you are interested in
                  details related to Google's use of data from websites and
                  applications using Google services, we encourage you to review
                  that information.
                </p>
                <h4># 17: Do we track your behavior on our website?</h4>
                <p>
                  Yes, we use Google Analytics tools, which involve collecting
                  information about your activity on our website. These tools
                  have been described in detail in the question about
                  third-party cookies, so we will not repeat that information
                  here.
                </p>
                <h4># 18: How can you manage your privacy?</h4>
                <p>
                  The answer to this question is found in various places in this
                  privacy policy, especially when describing specific tools,
                  cookie consent, etc. Nevertheless, for your convenience, We
                  have summarized this information in one place below. Here is a
                  list of options for managing your privacy:
                </p>
                <p>
                  <ul>
                    <li>Cookie settings in your web browser</li>
                    <li>
                      Browser plugins that support cookie management, e.g.,
                      Ghostery
                    </li>
                    <li>Additional software for managing cookies</li>
                    <li>Incognito mode in your web browser</li>
                    <li>Cookie settings directly from our website</li>
                  </ul>
                </p>
                <h4># 19: What are server logs?</h4>
                <p>
                  Using the website involves sending queries to the server on
                  which the website is stored. Each query directed to the server
                  is recorded in server logs. The logs include, among other
                  things, your IP address, server date and time, information
                  about the web browser, and operating system you are using. The
                  logs are saved and stored on the server.
                </p>
                <p>
                  Data stored in server logs is not associated with specific
                  individuals using the website, and We do not use it to
                  identify you. Server logs serve as auxiliary material for
                  administering the website, and their contents are not
                  disclosed to anyone other than authorized individuals
                  responsible for server administration.
                </p>
                <h4># 20: Do we update this Privacy Policy?</h4>
                <p>
                  We are constantly working on improving and expanding our
                  services, which is why we may introduce new or change existing
                  functionalities or update this Privacy Policy. Updated Privacy
                  Policy will always be available on the website.
                </p>
                <h4># 21: How can you contact us?</h4>
                <p>
                  You can contact us at{" "}
                  <a href="mailto:hello@projectscope.eu">
                    hello@projectscope.eu
                  </a>{" "}
                  regarding any issues related to your personal data or this
                  Privacy Policy.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <CTA />
      <WipeScreen />
    </>
  )
}

PrivacyPolicy.Layout = Layout
export default PrivacyPolicy
